<template>
  <div v-if="subs" class="py-3">
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Sottoscrizioni Farmacia
    </h6>
    <div v-if="subs.sottoscrizioni">
      <b-row cols="1" cols-md="2" cols-xl="3">
        <template v-for="(sott, ind) in subs.sottoscrizioni">
          <b-col
            v-if="bloccoRinnoviArray.includes(sott.abbonamento.slug)"
            :key="ind"
            class="my-2 py-2 text-justify border-left border-danger bg-light-panel ml-1"
            style="
              align-items: flex-end !important;
              border-width: 5px !important;
            "
          >
            <b-col>
              <p class="fw-bolder text-primary mb-0">
                {{ sott.abbonamento.nome }}
              </p>
              <p class="mb-0 text-justify">
                <i>{{ sott.abbonamento.descrizione }}</i>
              </p>
            </b-col>
            <b-col class="text-left">
              <!-- <p class="mb-0 fw-bolder text-primary" v-if="sott.attivo == 1">
                Abbonamento attivo
                <b-icon icon="circle-fill" variant="success"></b-icon>
              </p> -->
              <!-- Deve essere mostrato come disattivato -->
              <p class="mb-0 fw-bolder text-danger">
                Abbonamento non attivo
                <b-icon icon="circle-fill" variant="danger"></b-icon>
              </p>
              <p class="mb-0">
                Data attivazione:
                <span>{{ sott.data_inizio | moment("DD/MM/YYYY") }}</span>
                <br />Scadenza:
                <span>31/12/2024</span>
              </p>

              <!-- <p
                class="mb-0 fw-bolder text-primary"
                v-if="
                  sott.attivo == 1 && sott.abbonamento.service.position_ref == 2
                "
              >
                {{ subs.connessa }} minuti Connessa spesi questo mese
              </p>
              
              <p v-else class="mb-0 text-light-panel">-</p> -->
            </b-col>
            <b-col class="mt-2" v-if="sott.payment && sott.attivo == 1">
              <template v-if="!user.company.dottorfarma">
                <a
                  class="text-decoration-none"
                  v-if="sott.abbonamento.wc_product_id == 40"
                  :href="linkPagamenti + '4' + sott.abbonamento.wc_product_id"
                  target="_blank"
                >
                  <strong
                    class="text-white bg-prim-grad-1 text-uppercase fs-5 px-4 py-1"
                  >
                    vedi fattura
                  </strong>
                </a>
                <a
                  class="text-decoration-none"
                  v-else
                  :href="linkPagamenti + sott.abbonamento.wc_product_id"
                  target="_blank"
                >
                  <strong
                    class="text-white bg-prim-grad-1 text-uppercase fs-5 px-4 py-1"
                  >
                    vedi fattura
                  </strong>
                </a>
              </template>
              <strong
                v-else
                class="text-white bg-prim-grad-1 text-uppercase fs-5 px-4 py-1 point"
                @click="getRedirectLink(sott.abbonamento.hashid)"
                >vedi fattura</strong
              >
            </b-col>
          </b-col>
          <b-col
            v-else
            :key="ind"
            :class="sott.attivo == 1 ? 'border-primary' : 'border-danger'"
            class="my-2 py-2 text-justify border-left border-primary bg-light-panel ml-1"
            style="
              align-items: flex-end !important;
              border-width: 5px !important;
            "
          >
            <b-col>
              <p class="fw-bolder text-primary mb-0">
                {{ sott.abbonamento.nome }}
              </p>
              <p class="mb-0 text-justify">
                <i>{{ sott.abbonamento.descrizione }}</i>
              </p>
            </b-col>
            <b-col class="text-left">
              <p class="mb-0 fw-bolder text-primary" v-if="sott.attivo == 1">
                Abbonamento attivo
                <b-icon icon="circle-fill" variant="success"></b-icon>
              </p>
              <p class="mb-0 fw-bolder text-danger" v-else>
                Abbonamento non attivo
                <b-icon icon="circle-fill" variant="danger"></b-icon>
              </p>
              <p class="mb-0">
                Data attivazione:
                <span>{{ sott.data_inizio | moment("DD/MM/YYYY") }}</span>
                <br />Scadenza:
                <span>{{ sott.data_fine | moment("DD/MM/YYYY") }}</span>
              </p>
              <p
                class="mb-0 fw-bolder text-primary"
                v-if="
                  sott.attivo == 1 && sott.abbonamento.service.position_ref == 2
                "
              >
                {{ subs.connessa }} minuti Connessa spesi questo mese
              </p>
              <p v-else class="mb-0 text-light-panel">-</p>
            </b-col>
            <b-col class="mt-2" v-if="sott.payment && sott.attivo == 1">
              <template v-if="!user.company.dottorfarma">
                <a
                  class="text-decoration-none"
                  v-if="sott.abbonamento.wc_product_id == 40"
                  :href="linkPagamenti + '4' + sott.abbonamento.wc_product_id"
                  target="_blank"
                >
                  <strong
                    class="text-white bg-prim-grad-1 text-uppercase fs-5 px-4 py-1"
                  >
                    vedi fattura
                  </strong>
                </a>
                <a
                  class="text-decoration-none"
                  v-else
                  :href="linkPagamenti + sott.abbonamento.wc_product_id"
                  target="_blank"
                >
                  <strong
                    class="text-white bg-prim-grad-1 text-uppercase fs-5 px-4 py-1"
                  >
                    vedi fattura
                  </strong>
                </a>
              </template>
              <strong
                v-else
                class="text-white bg-prim-grad-1 text-uppercase fs-5 px-4 py-1 point"
                @click="getRedirectLink(sott.abbonamento.hashid)"
                >vedi fattura</strong
              >
            </b-col>
          </b-col>
        </template>
      </b-row>
      <p class="fw-bolder text-left" v-if="subs.sottoscrizioni.length == 0">
        Nessuna sottoscrizione.
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "Sottoscrizioni",
  props: [],
  computed: {
    linkPagamenti: function () {
      // var baseUrl = "https://pagamenti.federfarma.it";
      var baseUrl = this.pagamenti;
      console.log(this.pagamenti);
      if (this.user && this.user.company) {
        baseUrl = baseUrl + "?df_token=" + this.user.company.hashid;
        baseUrl += "&acquisto_corrente=";
      }
      return baseUrl;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      subs: null,
      loading: false,
      vetrinaLink: null,
      pagamenti: process.env.VUE_APP_PAGAMENTI_FEDERFARMA_URL,
      bloccoRinnoviArray: [
        "dottorfarma-sperimentazioni-23",
        "dottorfarma-standard-24",
        "dottorfarma-sperimentazioni-aderenti-24",
        "dottorfarma-sperimentazioni-nonaderenti-24"
      ]
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      const params = { client: 0 };
      companyService
        .getSubscriptions(params)
        .then(function (resp) {
          // //console.log(resp);
          self.subs = resp.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
    getVetrinaLink() {
      const self = this;
      self.loading = true;
      companyService
        .getVetrinaRedirectLink()
        .then(function (resp) {
          self.vetrinaLink = resp.data.data;
        })
        .catch(function () {
          self.errorAlert("Non è stato possibile recuperare il link");
          self.loading = false;
        });
    },
    getRedirectLink(productHashid) {
      const self = this;
      companyService
        .getFederfarmaPaymentLink(productHashid)
        .then(function (resp) {
          if (resp.data.data.redirect) {
            // window.location.href = resp.data.data.redirect;
            let link = document.createElement("a");
            link.href = resp.data.data.redirect;
            link.target = "_blank";
            link.click();
          }
        })
        .catch(function (error) {
          // self.$refs["spin-" + ind][0].hidden = true;
          // self.$refs["spn-" + ind][0].hidden = false;
          self.errorAlert("Non è stato possibile proseguire");
        });
    },
    setAcquistoPost(ind) {
      var hash = this.subs.opzioni[ind].hashid;
      this.$refs["spin-" + ind][0].hidden = false;
      this.$refs["spn-" + ind][0].hidden = true;
      var self = this;
      companyService
        .gotToBuy(hash)
        .then(function (resp) {
          if (resp.data.data.already) {
            window.scrollTo(0, 0);
            self.infoAlert("Sottoscrizione già attiva!");
            self.$refs["spin-" + ind][0].hidden = true;
            self.$refs["spn-" + ind][0].hidden = false;
          }
          if (resp.data.data.redirect) {
            window.location.href = resp.data.data.redirect;
          }
        })
        .catch(function (error) {
          self.$refs["spin-" + ind][0].hidden = true;
          self.$refs["spn-" + ind][0].hidden = false;
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
  },
  created() {
    this.fetchData();
    // this.getVetrinaLink();
  },
};
</script>
