<template>
  <div v-if="subs" class="py-3">
    <div v-if="subs.opzioni">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Opzioni di acquisto
      </h6>
      <b-row cols="1" cols-md="2" cols-xl="3">
        <b-col
          v-for="(opt, ind) in subs.opzioni"
          :key="ind"
          class="my-2"
          style="min-height: 100%"
        >
          <div
            style="min-height: 100%"
            :class="
              selectedOption && selectedOption.hashid === opt.hashid
                ? 'bg-sec-4 border-info'
                : 'panel__box__shadow bg-light-panel border-light-panel'
            "
            class="border general_border py-3 d-flex flex-column align-items-center"
          >
            <!-- <img :src="opt.service.logo" alt="service logo" /> -->
            <p class="fw-bolder text-primary mb-0">{{ opt.nome }}</p>
            <p class="mb-0 font-italic">{{ opt.descrizione }}</p>
            <p
              class="mb-0 mt-auto text-primary fw-bolder"
              v-if="opt.durata_solare"
            >
              Valido fino al 31/12/{{ year }}
            </p>
            <p
              class="mb-0 mt-auto text-primary fw-bolder"
              v-else-if="opt.wc_product_id === 35"
            >
              Valido fino a fine sperimentazione
            </p>
            <p class="mb-0 mt-auto text-primary fw-bolder" v-else>
              Valido {{ opt.durata }} mesi
            </p>

            <template>
              <p v-if="opt.prezzo > 0" class="mb-0 text-primary fw-bolder">
                {{ opt.prezzo.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }} iva
                <span v-if="!opt.iva_inclusa"> esclusa </span>
                <span v-else> inclusa </span>
              </p>
              <p v-else class="mb-0 text-primary fw-bolder" style="opacity: 0">
                Gratuita
              </p>
              <b-button
                @click="selectSubscription(opt)"
                variant="info info_gradient rounded-pill text-white fw-bolder spacing_1 mb-0 mt-auto"
              >
                <span> Scegli </span>
              </b-button>
            </template>
          </div>
        </b-col>
      </b-row>
      <div v-if="selectedOption && selectedOption.dettaglio" class="mt-2">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Dettagli della sottoscrizione
        </h6>
        <p
          class="mt-2 mb-4 text-dark text-justify"
          v-html="selectedOption.dettaglio"
        ></p>
        <b-button
          @click="$emit('selected-subscription', selectedOption)"
          class="mx-auto"
          variant="primary primary_gradient rounded-pill text-white fw-bolder spacing_1 mb-0 mt-4"
          >Seleziona sottoscrizione "{{ selectedOption.nome }}"</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
const currentYear = new Date().getFullYear();
export default {
  name: "SottoscrizioniSelector",
  props: [],
  computed: {
    linkPagamenti: function () {
      // var baseUrl = "https://pagamenti.federfarma.it";
      var baseUrl = this.pagamenti;
      if (this.user && this.user.company) {
        baseUrl = baseUrl + "?df_token=" + this.user.company.hashid;
        baseUrl += "&acquisto_corrente=";
      }
      return baseUrl;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      subs: null,
      loading: false,
      vetrinaLink: null,
      pagamenti: process.env.VUE_APP_PAGAMENTI_FEDERFARMA_URL,
      selectedOption: null,
      year: currentYear,
      dismissione: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      const params = { client: 0 };
      companyService
        .getSubscriptions(params)
        .then(function (resp) {
          // //console.log(resp);
          self.subs = resp.data.data;
        })
        .catch(function (error) {
          console.log(error);
          var msg = "Non è stato possibile recuperare le sottoscrizioni";
          if (error.status === 403) {
            self.dismissione = true;
            msg = error.data.message;
          }
          self.errorAlert(msg);
        });
    },
    selectSubscription(opt) {
      this.selectedOption = opt;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
