<template>
  <div class="text-justify" v-if="user && user.company">
    <h6 class="text-center">
      CONTRATTO PER LA LICENZA D’USO DELLA PIATTAFORMA “Dottorfarma”
    </h6>
    <br />
    <p>
      <strong>Promofarma S.r.l.</strong>, con sede in Roma, via Emanuele
      Filiberto n. 190, , codice fiscale e P.IVA n. 05317231008, in persona del
      legale rappresentante pro tempore, di seguito indicata anche come
      «Fornitore» o «Licenziante».
    </p>
    <h6 class="text-center">E</h6>
    <p>
      <span class="text-capitalize">
        {{ user.company.nome }} {{ user.company.cognome }}</span
      >, <span class="text-uppercase">CF {{ user.company.codice_fiscale }}</span
      >, nella sua qualità di Titolare/Socio/Direttore/ della Farmacia
      <span class="text-uppercase"> {{ user.company.denominazione }}</span> con
      codice di tracciabilità
      <span class="text-uppercase">
        {{ user.company.codice_tracciabilita }}</span
      >
      e sede in
      <span class="text-capitalize">
        {{ user.company.frazione }} - {{ user.company.comune }} </span
      >,
      <span class="text-capitalize">
        {{ user.company.indirizzo }} {{ user.company.cap }} </span
      >, p.iva {{ user.company.partita_iva }}
      <br />
      Convengono quanto segue.

      <br />
    </p>
    <h6 class="">1. Definizioni</h6>
    <p>
      a. Con l’espressione “Dottorfarma” o la “Piattaforma” si intende la
      piattaforma accreditata anche come dispositivo medico di proprietà di
      Promofarma S.r.l., che permette di organizzare e gestire i servizi resi
      dalla farmacia in favore dei pazienti, in particolare dei pazienti
      cronici;
      <br />
      b. Con l’espressione “Licenziatario” si intende la società o l’impresa
      individuale titolare di farmacie, che sottoscrive il presente contratto al
      fine di dotare la farmacia del supporto della Piattaforma; c. Con
      l’espressione “Servizi” si intendono i servizi offerti dalla Piattaforma
      ed accessibili tramite la dashboard;
      <br />
      d. Con l’espressione “Utente” si intende il farmacista dipendente o
      collaboratore dell’Acquirente, il quale si registra alla Piattaforma e
      utilizza i Servizi;
      <br />
      e. Con l’espressione “Cittadino” o “Paziente” si intende il cittadino
      cliente della farmacia che si registra alla Piattaforma ed usufruisce dei
      servizi messi a disposizione in autonomia o con il supporto del
      farmacista.
      <br />
      f. Con l’espressione “Allegati” si intendono la designazione come
      Responsabile del trattamento dei dati di Promofarma s.r.l. e Qwince s.r.l.
      che saranno sottoscritti separatamente, pur essendo parte integrante del
      presente contratto.
      <br />
    </p>
    <h6 class="">2. Natura e oggetto del contratto</h6>
    <p>
      2.1. Il presente contratto ha lo scopo di disciplinare i rapporti tra
      Promofarma S.r.l. e il Licenziatario e le modalità di acquisizione della
      licenza d’uso della Piattaforma e di fruizione dei Servizi messi a
      disposizione dal Fornitore.

      <br />
      2.2. Con la conclusione del presente contratto al Licenziatario viene
      concessa una licenza d’uso non esclusiva, per il solo territorio italiano,
      della Piattaforma Dottorfarma al momento della conclusione del contratto
      di licenza d’uso. Qualora il Licenziatario sia proprietario di più
      Farmacie ovvero sia responsabile legale di una società titolare di più
      Farmacie, dovrà sottoscrivere un contratto per ciascuna Farmacia.
      L’utilizzo e comunque l’accesso al Servizio resta in via esclusiva
      riservato alla sola Farmacia indicata all’atto della registrazione sul
      portale
      <a href="www.federfarma.it" target="_blank"> www.federfarma.it</a> .
      <br />
    </p>
    <h6 class="">3. Modalità di conclusione del contratto</h6>
    <p>
      3.1. Il contratto tra il Licenziatario e il Fornitore per l’acquisizione
      della licenza d’uso della Piattaforma si perfeziona a distanza tramite
      strumenti telematici,attraverso la manifestazione di volontà raccolta con
      un click nell’apposita casella che equivale a sottoscrizione. Il contratto
      potrà essere consultato nella propria area riservata.

      <br />
      3.2. La licenza d’uso della Piattaforma è concessa ai Licenziatari dal 1
      gennaio al 31 dicembre di ciascun anno, dietro pagamento di quanto
      previsto e comunicato prima della sottoscrizione del contratto. In nessun
      caso è previsto il rimborso del corrispettivo.
      <br />
      3.3 Il costo del servizio per ogni sede farmaceutica include 50 min/mese
      di Connessa e fino a 1.000 cittadini arruolabili.
      <br />
    </p>
    <h6 class="">4. Modalità di attivazione della Piattaforma</h6>
    <p>
      4.1. In seguito alla conclusione del contratto di acquisizione della
      licenza d’uso della Piattaforma, il Licenziatario potrà accedere alla
      Piattaforma, previa autenticazione.

      <br />
    </p>
    <h6 class="">5. Obbligazioni del Fornitore</h6>
    <p>
      5.1. Promofarma S.r.l. si impegna a mettere a disposizione la Piattaforma
      oggetto della licenza d’uso ed a permettere l’accesso ai Servizi ai
      dipendenti e ai collaboratori del Licenziatario nonché ai Cittadini.
      <br />
    </p>
    <h6 class="">6. Condizioni per l’utilizzo della Piattaforma</h6>
    <p>
      6.1. Il Licenziatario identifica tra i propri dipendenti e collaboratori
      gli Utenti che avranno accesso alla Piattaforma e comunicaun codice
      d’iscrizione da utilizzare al momento della registrazione su
      www.dottorfarma.it.
      <br />
      6.2. L’Utente al momento del primo accesso conclude la registrazione alla
      Piattaforma, previa accettazione dei Termini e Condizioni d’Uso della
      Piattaforma e dell’informativa sul trattamento dei dati personali.

      <br />
      6.3. Il Paziente al momento del primo accesso conclude la registrazione
      alla Piattaforma, inserendo i dati richiesti dal form di registrazione
      nonché la propria username e password, previa accettazione dei Termini e
      Condizioni d’Uso della Piattaforma e dell’informativa sul trattamento dei
      dati personali per la finalità di registrazione.

      <br />
      6.4. Il Licenziatario è responsabile dell’uso che ciascun Utente e ciascun
      Paziente fa della Piattaforma; in caso di contestazioni da parte di terzi
      riguardo a qualsiasi dato, contenuto o condotta posta in essere dai
      Pazienti, dagli Utenti o dal Licenziatario mediante l’utilizzo della
      Piattaforma, il Licenziatario se ne assume la piena responsabilità, sia
      nei confronti di terzi sia nei confronti di Promofarma S.r.l..

      <br />
      6.5. Promofarma S.r.l. non potrà essere ritenuta in alcun modo
      responsabile per eventuali danni di qualunque natura patiti dal
      Licenziatario, dall’Utente e/o dal Paziente in conseguenza dell’utilizzo
      non autorizzato o comunque abusivo delle credenziali posto in essere da
      parte di soggetti terzi.
      <br />
    </p>
    <h6 class="">7. Proprietà della Piattaforma</h6>
    <p>
      7.1. Promofarma S.r.l. è proprietario esclusivo dei contenuti della
      Piattaforma, ivi compresi, a titolo esemplificativo e non esaustivo,
      l’architettura della stessa, i codici sorgente, i testi, i documenti, le
      immagini, i loghi, le fotografie, il layout delle pagine, il design, il
      know-how e le interfacce grafiche.

      <br />
      7.2. Promofarma S.r.l. è titolare esclusivo dei diritti di proprietà
      intellettuale ed industriale del dispositivo medico Dottorfarma. Il
      fabbricante del dispositivo medico Dottorfarma è Qwince Innovation s.r.l.;
      Dottorfarma è una configurazione del dispositivo medico PuntoFarma
      (Registrazione Elenco Dispositivi Medici del Ministero della Salute n.
      2305257 – Normativa di riferimento: Reg. UE 2017/745 – Classe I) .

      <br />
      7.3. I software sono di esclusiva proprietà di Promofarma S.r.l.; è fatto
      divieto al Licenziatario di cederli, affittarli, noleggiarli, prestarli,
      darli in sublicenza a terzi, o comunque di consentirne l’uso da parte di
      terzi, ad eccezione degli Utenti e dei Pazienti, sia a titolo gratuito che
      a titolo oneroso.

      <br />
      7.4. La concessione della licenza d’uso non attribuisce al Licenziatario
      alcun diritto sul programma sorgente; tutte le tecniche, gli algoritmi e i
      procedimenti contenuti nei programmi e nella relativa documentazione sono
      informazioni riservate di proprietà di Promofarma S.r.l..

      <br />
      7.5. Il Licenziatario non può copiare in tutto o in parte i programmi
      concessi in uso, né in forma stampata, né in forma leggibile
      dall’elaboratore.

      <br />
      7.6. Il Licenziatario si obbliga a non effettuare alcuna attività di
      Reverse Engineering, né modificare i software, o incorporarli in tutto o
      in parte in altri programmi, o decompilarli, o disassemblarli, se non nei
      limiti in cui tale facoltà costituisca un suo diritto inderogabile a
      termini di legge.

      <br />
    </p>
    <h6 class="">8. Responsabilità del Fornitore</h6>
    <p>
      8.1. Promofarma S.r.l. non può essere ritenuta responsabile per qualsiasi
      errore (inclusi errori materiali) e/o interruzione del funzionamento della
      Piattaforma ad essa non imputabile.

      <br />
      8.2. Promofarma S.r.l. non rilascia alcuna garanzia e declina ogni
      responsabilità, nei limiti previsti dalla legge, per eventuali danni,
      diretti o indiretti che possano derivare al Licenziatario, all’Utente e/o
      al Paziente dall’uso o dall’impossibilità di utilizzo della Piattaforma
      per cause ad essa non imputabili.

      <br />
      8.3. Promofarma S.r.l. non è responsabile per l’eventuale impossibilità di
      accesso e/o utilizzo della Piattaforma che dovesse dipendere da problemi
      di connessione ad Internet e/o da eventuali malfunzionamenti tecnici della
      stessa.

      <br />
      8.4. Promofarma S.r.l. non assume alcuna responsabilità per danni, pretese
      o perdite, dirette o indirette, derivanti al Licenziatario, all’Utente e/o
      al Paziente per il mancato e/o difettoso funzionamento delle
      apparecchiature elettroniche o di terzi, di collegamenti telefonici e/o
      telematici non gestiti direttamente dalla stessa o dai suoi fornitori.

      <br />
    </p>
    <h6 class="">9. Responsabiità per la prestazione sanitaria</h6>
    <p>
      9.1. Promofarma S.r.l. non può ritenersi in alcun modo responsabile o
      corresponsabile in relazione ai dati e alle informazioni caricate dal
      Licenziatario, dall’ Utente e/o dal Paziente nonché in relazione alla
      responsabilità professionale contestuale e/o conseguente all’erogazione
      della prestazione sanitaria effettuata dal Licenziatario utilizzando i
      Servizi messi a disposizione dalla Piattaforma.

      <br />
      9.2. Ai sensi delle norme di legge attualmente in vigore, la
      responsabilità professionale della prestazione sanitaria erogata anche a
      distanza risulta in capo ai professionisti abilitati, in presenza ed in
      telemedicina; quindi, nessuna richiesta potrà essere promossa nei
      confronti di Promofarma S.r.l..

      <br />
      9.3. Promofarma S.r.l. potrà essere eventualmente responsabile di
      eventuali disservizi tecnici e/o organizzativi della Piattaforma, fermo
      restando quanto esplicitato all’articolo 8 del presente Contratto.

      <br />
    </p>
    <h6 class="">10. Limitazioni nell’erogazione dei Servizi</h6>
    <p>
      10.1. Promofarma S.r.l. si riserva il diritto di modificare, sospendere o
      interrompere, in tutto o in parte, i Servizi in qualsiasi momento, anche
      senza preavviso.

      <br />
      10.2. A titolo esemplificativo, ma non esaustivo, ciò potrà avvenire per
      la mancanza di connessione Internet del dispositivo utilizzato dal
      Licenziatario, dall’Utente e/o dal Paziente, per il malfunzionamento dei
      server o dei servizi resi da terze parti fornitrici ed altri dispositivi
      elettronici anche non facenti parte integrante della rete Internet, per il
      malfunzionamento dei software installati, virus informatici, nonché da
      azioni di hacker o altri utenti aventi accesso alla rete.

      <br />
      10.3. Promofarma S.r.l. manterrà informato il Licenziatario di eventuali
      sospensioni e/o interruzioni dei Servizi mediante comunicazioni su
      www.dottorfarma.it e/o su www.federfarma.it .

      <br />
    </p>
    <h6 class="">11. Riservatezza</h6>
    <p>
      11.1. Il Licenziatario si impegna a mantenere strettamente riservate e
      confidenziali tutte le informazioni relativa a Promofarma S.r.l., alla
      Piattaforma ed ai Servizi (di seguito, “<strong>
        Informazioni Segrete</strong
      >”), di cui possa venire direttamente o indirettamente a conoscenza nel
      corso dell’esecuzione del presente Contratto. Le informazioni di cui il
      Licenziatario può venire a conoscenza in esecuzione del presente
      Contratto, costituiscono patrimonio industriale ed intellettuale di
      Promofarma S.r.l. oggetto di privativa oppure da considerarsi
      “Informazioni segrete” ai sensi degli artt. 98 e 99 d.lgs. n. 30/2005 e in
      quanto tali dovranno essere mantenute segrete anche in seguito alla
      conclusione del Contratto. Il Licenziatario riconosce pertanto il
      carattere segreto e sostanziale delle informazioni, delle conoscenze e
      delle nozioni che costituiscono il know how trasferito.

      <br />
      11.2. Il Licenziatario si impegna pertanto ad utilizzare le Informazioni
      Segrete di Promofarma S.r.l. di cui dovesse venire a conoscenza solo ed
      esclusivamente ai fini dell’esecuzione del Contratto, a non trasmetterle
      né diffonderle o condividerle, per alcun motivo, con terze parti.

      <br />
      11.3. Il Licenziatario si impegna a far rispettare i presenti obblighi di
      riservatezza da tutti i propri soci, amministratori, dipendenti e
      collaboratori in genere.
      <br />
      11.4. Il Licenziatario garantisce di avere adottato tutte le misure
      tecniche ed organizzative idonee a tutelare la riservatezza delle
      Informazioni segrete dell’altra parte.

      <br />
      11.5. Gli obblighi di riservatezza non si applicano a tutte le
      informazioni che:
    </p>
    <!-- <ul style="list-style-type: lower-alpha"> -->
    <ul>
      <li>
        a. erano di dominio pubblico al momento della loro divulgazione o che in
        seguiti siano diventate di dominio pubblico, purché non in conseguenza
        di una violazione del presente Contratto o
      </li>
      <li>
        b. erano a disposizione della parte ricevente precedentemente alla data
        di sottoscrizione del presente accordo, eccetto il caso di violazione di
        altro accordo di riservatezza eventualmente gravante su detta parte;
      </li>
      <li>
        c. siano state lecitamente ottenute e/o autonomamente create da terzi;
      </li>
      <li>
        d. la loro comunicazione e/o divulgazione sia stata imposta da un
        provvedimento proveniente da una pubblica autorità (giurisdizionale o
        non), dalla legge o dalla necessità di far valere un diritto in
        giudizio. In tal caso, sarà obbligo della parte ricevente informare
        tempestivamente e preventivamente la Parte titolare delle Informazioni
        Confidenziali al fine di concordare i tempi, le forme e i contenuti
        della richiesta comunicazione e/o divulgazione.
      </li>
    </ul>
    <p>
      11.6. Il mancato rispetto dell’obbligo di riservatezza comporta la
      risoluzione del presente contratto, oltre al risarcimento dei danni sia
      morali che patrimoniali che la parte danneggiata dovesse avere subito.

      <br />
      11.7. Tali impegni di riservatezza sono validi ed efficaci per tutta la
      durata del presente Contratto e successivamente alla cessazione dello
      stesso, per qualsiasi causa la stessa abbia luogo.
      <br />
    </p>
    <h6 class="">12. Durata</h6>
    <p>
      12.1. L’efficacia del contratto decorre dalla data di conclusione dello
      stesso con le modalità di cui all’art. 3.
      <br />
      12.2. La licenza d’uso della Piattaforma si intende concessa fino al
      31/12/2023 di ciascun anno cui il pagamento si riferisce.

      <br />
      12.3 Al termine del contratto è prevista la possibilità di rinnovo della
      Licenza alle condizioni che verranno rese note da Promofarma S.r.l..

      <br />
    </p>
    <h6 class="">13. Recesso e risoluzione</h6>
    <p>
      13.1. Le parti potranno recedere dal contratto in qualsiasi momento
      mediante l’invio di comunicazione scritta a mezzo Posta Elettronica
      Certificata con un preavviso di 30 giorni.

      <br />
      13.2. Le parti avranno il diritto di risolvere il presente Contratto ex
      art. 1456 c.c., con effetto immediato, mediante l’invio di comunicazione
      scritta a mezzo Posta Elettronica Certificata, in caso di violazione o
      inosservanza di una delle obbligazioni indicate rispettivamente negli
      artt. 5, 7, 11

      <br />
      14. Resta salvo in capo alle parti il diritto al risarcimento del danno
      nei suddetti casi di risoluzione.
      <br />
    </p>
    <h6 class="">14. Cessione – Subcontraenti</h6>
    <p>
      14.1. Il presente contratto non è cedibile dalle parti a terzi, in
      mancanza del consenso da parte del contraente ceduto. È vietata la
      cessione, in tutto o in parte, dei crediti derivanti dall’esecuzione del
      contratto.
      <br />
    </p>
    <h6 class="">15. Trattamento dei dati personali</h6>
    <p>
      15.1. Le Parti si danno reciprocamente atto che i dati di Promofarma
      S.r.l. e del Licenziatario e i dati personali dei rispettivi dipendenti
      e/o consulenti potranno essere trattati esclusivamente in relazione e per
      le finalità di esecuzione del Contratto.

      <br />
      15.2. Le Parti si impegnano ad effettuare il trattamento dei dati
      personali acquisiti nell’esecuzione o in dipendenza del presente contratto
      nel rispetto delle disposizioni del Regolamento UE 2016/679 relativo alla
      protezione delle persone fisiche con riguardo al trattamento dei dati
      personali e di ogni legge e provvedimento in materia di protezione dei
      dati personali di volta in volta applicabile (in prosieguo congiuntamente
      “Leggi Privacy”).

      <br />
      15.3. Relativamente ai dati personali trattati da Promofarma S.r.l. per
      conto del Licenziatario in esecuzione del contratto, Promofarma S.r.l.
      riceverà apposita nomina in qualità di Responsabile del trattamento ai
      sensi del Reg. UE 2016/679, da considerarsi parte integrante del
      contratto.

      <br />
    </p>
    <h6 class="">16. Miscellanea</h6>
    <p>
      16.1. L’eventuale tolleranza da parte di Promofarma S.r.l. di
      comportamenti del Licenziatario p.osti in essere in violazione delle
      disposizioni contenute nel presente contratto non costituisce rinuncia ai
      diritti derivanti dalle disposizioni violate né al diritto di esigere
      l’esatto adempimento di tutti i termini e di tutte le condizioni qui
      previsti. Nessuna rinuncia da parte di Promofarma S.r.l. a qualsiasi
      condizione o a far valere qualsiasi violazione di una disposizione, patto,
      dichiarazione o garanzia contenuti in questo contratto sarà efficace a
      meno che non sia fatta per iscritto, e nessuna rinuncia in uno o più casi
      sarà ritenuta essere un’ulteriore o continua rinuncia di tali condizioni o
      a far valere tali violazioni in altri casi o una rinuncia di qualsiasi
      altra condizione o a far valere qualsiasi violazione di altre
      disposizioni, patti, dichiarazioni o garanzie.
      <br />
      16.2. Nel caso in cui una o più disposizioni del presente contratto
      dovesse essere ritenuta invalida o non eseguibile, l’invalidità o la non
      eseguibilità di tale disposizione non si estenderà, nella misura massima
      consentita dalla legge, alle altre disposizioni del presente contratto, le
      quali manterranno pieno vigore ed efficacia.

      <br />
    </p>
    <h6 class="">17. Legge applicabile</h6>
    <p>
      17.1. Il presente Contratto ed i relativi diritti ed obblighi delle Parti
      dallo stesso derivanti saranno retti ed interpretati secondo le leggi
      della Repubblica Italiana.

      <br />
      17.2. Il riferimento ad una legge si intende come riferimento a quella
      stessa legge come modificata, aggiornata, integrata o sostituita e ad ogni
      provvedimento emesso in dipendenza da tale legge.

      <br />
    </p>
    <h6 class="">18. Foro competente</h6>
    <p>
      18.1. Ogni controversia relativa al presente Contratto (ivi incluse, a
      titolo meramente esemplificativo e non esaustivo, quelle relative
      all’interpretazione e/o all’esecuzione e/o alla risoluzione dello stesso)
      sarà riservata alla competenza esclusiva del Foro di Roma.
      <br />
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ContrattoStandard",
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
};
</script>
